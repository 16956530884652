<template lang="pug">
  b-btn.m-3(@click='actionCompleted()') test2
</template>
<script>
export default {
  created() {
    this.storeQueryParameters()
  },
  methods: {
    storeQueryParameters() {
      const transactionId = this.$route.query.transaction_id
      const params = {
        transaction_id: transactionId
      }

      localStorage.setItem('surfParams', JSON.stringify(params))
    },
    getQueryParameters() {
      return JSON.parse(localStorage.getItem('surfParams'))
    },

    actionCompleted() {
      // Retrieve and log localstorage values
      const surfParams = this.getQueryParameters()
      console.log('Transaction ID: %o', surfParams.transaction_id)

      // localhost:3000/api/v1/challenge_transactions/:transaction_id/callback
      // giveaways.joinsurf-staging.com/api/v1/challenge_transactions/:transaction_id/callback
      // giveaways.joinsurf.com/api/v1/challenge_transactions/:transaction_id/callback

      const url = `https://giveaways.joinsurf-staging.com/api/v1/entries/challenge_transactions/${surfParams.transaction_id}/callback`
      //API Call
      fetch(url)
        .then((response) => {
          return response.json()
        })
        .then((json) => {
          const redirectUrl = 'https://giveaways.joinsurf-staging.com' + json.redirect_url
          window.open(redirectUrl, '_blank').focus()
        })
        .catch((error) => {
          console.log('Error calling API: %o', error)
        })
    }
  }
}
</script>
